import React from "react";
import "./styles.scss";
import { IconExternalLinkTrans } from "../Icons";

export default function Page({children, heading = "", subheading = "", className = ""}) {
    return <section className={`page ${className}`}>
        {heading && <header className="page-header">
            <h1>{heading}</h1>
            {subheading && <p>{subheading}</p>}
        </header>}
        {children}
    </section>
}

export const GlassPaneContainer = ({children, html, landingPage}) => <div className="glass-pane-container">
    {html ? 
        <div className={`content-container ${landingPage ? " landing-page" : ""}`} dangerouslySetInnerHTML={{__html: html}}/> :
        <div className={`content-container ${landingPage ? " landing-page" : ""}`}>{children}</div>
    }
</div>

export const ExternalLinkIcon = () => <sup className="icon"><IconExternalLinkTrans size={1.2}/></sup>